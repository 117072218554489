const words = [
    "sul",
    "nooboo",
    "feebee",
    "oh",
    "lay",
    "dag",
    "whippna",
    "choba",
    "sherb",
    "bloo",
    "boobasnot",
    "hooba",
    "noobie",
    "veena",
    "fredishay",
    "shamoo",
    "firbs",
    "chum-cha",
    "fro",
    "frash",
    "grouw",
    "kowlenin",
    "kooj",
    "nash",
    "na",
    "foop",
    "ooh",
    "krem",
    "milay",
    "letish",
    "shanga",
    "sperk",
    "fretishe",
    "litzergam",
    "nurbler",
    "delco",
    "webney",
    "kabuna",
    "ah",
    "gwanda",
    "blitz",
    "docka",
    "morpher",
    "vou",
    "chee",
    "ga",
    "neep",
    "klop",
    "gir",
    "ba",
    "abanoop",
    "laka",
    "om",
    "noop",
    "dow",
    "dowl",
    "mala",
    "nono",
    "sato",
    "seeta",
    "acko",
    "ono",
    "afenk",
    "snoz",
    "mee-la",
    "balami",
    "sula",
    "sons",
    "kana",
    "deg",
    "kondo",
    "gerbits",
    "snanna",
    "ongie",
    "za",
    "gleb",
    "clops",
    "myshuno",
    "miza",
    "shooflee",
    "nart",
    "gronk",
    "shurb",
    "frooby",
    "fazoo",
    "tor",
    "maboo",
    "zep",
    "wub",
    "mezino",
    "dwam",
    "ne",
    "yibs",
    "yib",
    "neeba",
    "zow",
    "caba",
    "garsha",
    "zagadoo",
    "marf",
    "apper",
    "nerk",
    "bweb",
    "zerpa",
    "stamby",
    "heb",
    "sugnorg",
    "fuens",
    "fweeka",
    "foo",
    "va",
    "fa",
    "tinip",
    "nip",
    "aw",
    "cou",
    "taw",
    "debool",
    "tig",
    "wut",
    "sa",
    "dib",
    "maladai",
    "arogaba",
    "yibsy",
    "neib",
    "noom",
    "grobel",
    "disgrobel",
    "meshka",
    "gedna",
    "noobty",
    "favu",
    "shushubie",
    "smee",
    "bartem",
    "zaebeltooty",
    "mekchate",
    "mek",
    "wetsul",
    "swobe",
    "swaybul",
    "zapzing",
    "aut",
    "treeb",
    "flurze",
    "dufka",
    "vendu",
    "oosh",
    "sedna",
    "charba",
    "vadish",
    "nabe",
    "discufa",
    "swaybe",
    "beleesh",
    "euranka",
    "prooshtis",
    "be",
    "nay",
    "zoob",
    "fleeba",
    "me",
    "shpansa",
    "gab",
    "vanu",
    "roo",
    "flooz",
    "woohoo",
    "awasa",
    "gam",
    "chibna",
    "renato",
    "choo",
    "choon",
    "choony",
    "shoo",
    "flee",
    "shoona",
    "dorby",
    "grauw",
    "bum",
    "la",
    "zu",
    "bagoo",
    "gorney",
    "whipnow",
    "baba",
    "badeesh",
    "ern",
    "zor",
    "lalo",
    "gloopy",
    "burb",
    "frabbit",
    "fleeny",
    "zerbo",
    "nork",
    "smustle",
    "nifler",
    "plooba",
    "norb",
    "blousa",
    "pipty",
    "ta",
    "welb",
    "zaroo",
    "hipta",
    "yarma",
    "ooba",
    "lukimazy",
    "hopple",
    "borfna",
    "fwingle",
    "zibbs",
    "bor",
    "simsa",
    "yebsey",
    "zurzoh",
    "mayzie",
    "grobe",
    "badipsa",
    "kweb",
    "oofa",
    "dabel",
    "doops",
    "fergoob",
    "kipsy",
    "mebzi",
    "jepsi",
    "craybal",
    "wepsa",
    "gon",
    "zebanay",
    "swoobie",
    "gunikso",
    "yorbs",
    "fleebs",
    "mibsy",
    "zorknu",
    "wona",
    "bileptu",
    "avoo",
    "evawop",
    "worten",
    "zid",
    "globbay",
    "gork",
    "army-putar",
    "meepso",
    "geebuglon",
    "vitsu",
    "glib",
    "mebbiwaba",
    "moocha",
    "squib",
    "skeeba",
    "wanbah",
    "forbzoo",
    "yepron",
    "gartzel",
    "vana",
    "nib",
    "yipsee",
]

export default words